'use client';

import cookieToMap from '@haaretz/s-cookie-utils/cookieToMap';
import React from 'react';

import type { HtzUser, PPMessageProps } from '@haaretz/s-types';

export type User = HtzUser & { isLoggedIn: boolean };

export type Cookie = {
  ppResponseMessage?: PPMessageProps;
  OptanonConsent?: string;
  _htzwif?: string;
  acl?: string;
  userDetails?: Omit<HtzUser, 'anonymousId'>;
  user_details?: string;
};

const CookieContext = React.createContext<Cookie | undefined>(undefined);
const CookieSetter = React.createContext<((value?: string) => void) | undefined>(undefined);

function cookieDispenserFactory(cookie: string) {
  const cookieMap = cookieToMap(cookie, true);
  const { ppResponseMessage, userDetails, user_details, acl, _htzwif, OptanonConsent } = cookieMap;

  return { ppResponseMessage, userDetails, user_details, acl, _htzwif, OptanonConsent } as Cookie;
}

export function CookieProvider({ children }: React.PropsWithChildren) {
  const [cookie, setCookie] = React.useState<Cookie>({});

  const cookieSetter = React.useCallback((action?: string) => {
    setCookie(
      cookieDispenserFactory(
        typeof window === 'undefined' ? /* istanbul ignore next */ action ?? '' : document.cookie
      )
    );
  }, []);

  React.useEffect(() => {
    cookieSetter();
  }, [cookieSetter]);

  return (
    <CookieContext.Provider value={cookie}>
      <CookieSetter.Provider value={cookieSetter}>{children}</CookieSetter.Provider>
    </CookieContext.Provider>
  );
}

export default function useCookie() {
  const context = React.useContext(CookieContext);

  if (context === undefined) {
    throw new Error('useCookie must be used within a CookieProvider');
  }

  return context;
}

export function useSetCookie() {
  const context = React.useContext(CookieSetter);

  if (context === undefined) {
    throw new Error('useSetCookie must be used within a CookieProvider');
  }

  return context;
}

export function useCookieAtom() {
  return [useCookie(), useSetCookie()] as const;
}
