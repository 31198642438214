'use client';

import config from '@haaretz/l-config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import type { AppType } from '@haaretz/l-app-config/types';

const appType = config.get('appType') as AppType;

function useErrorBoundary(error: unknown) {
  if (error instanceof Error) {
    console.error('Fetch error:', error.message);
  } else {
    console.error('Fetch error:', error);
  }

  return false;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      retry: false,
      staleTime: 600000, // 10 minutes
      suspense: appType === 'site', // NOTE: Suspense is enabled for server components applications only
      useErrorBoundary,
    },
    mutations: {
      retry: false,
      useErrorBoundary,
    },
  },
});

export default function GlobalProviders({ children }: Readonly<React.PropsWithChildren>) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
