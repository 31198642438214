/**
 * A `px` to `rem` converter
 * **Warning:** This can potentially (and most likely will) break
 * the vertical rhythm and is meant for internal library use.
 *
 * @param context -
 *   The number, in pixels to be used as the base for converting `pxVal` to `rem`.
 *   Should be the font-size of the `html` element.
 * @param pxVal -
 *   The number, in pixels to convert into `rem`.
 *
 * @returns `pxVal` converted to `rem` based on `context`.
 *
 * @example
 * Single value

 * ```ts
 * pxToRem(16, 12) // => 0.75;
 * ```
 *
 * @example
 * Array of values

 * ```ts
 * pxToRem(6, 12, 24) // => [ 2, 4, ];
 * ```
 *
 * @internal
 */
export function pxToRem(context: number, pxValue: number): number;
/** @internal */
export function pxToRem(context: number, ...pxValue: Array<number>): Array<number>;
/** @internal */
export function pxToRem(context: number, pxValue: number | Array<number>): number | Array<number> {
  if (Array.isArray(pxValue)) return pxValue.map(x => x / context);
  return pxValue / context;
}
