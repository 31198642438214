import * as Types from '@haaretz/s-fragments/Types';

import { HtmlNodeFragmentGql } from '@haaretz/s-fragments/HTMLNode';
import { ImageFragmentGql } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragmentGql } from '@haaretz/s-fragments/ImageCrop';
import { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
import { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';
export type OnboardingElementFragment = (
  { campaignDetails?: string | null, campaignName?: string | null, featureName?: string | null, featureType?: string | null, id?: string | null, name?: string | null, targetDOMElement?: string | null, skin?: string | null, onboardingType?: string | null, htmlContent?: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )> | null, image?: (
    ImageFragment
    & { __typename: 'htz_image_Image' }
  ) | null, mobileHtmlContent?: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )> | null, mobileImage?: (
    ImageFragment
    & { __typename: 'htz_image_Image' }
  ) | null }
  & { __typename: 'OnboardingElement' }
);


export type OnboardingElementFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const OnboardingElementFragmentGql = `
    fragment OnboardingElementFragment on OnboardingElement {
  __typename
  campaignDetails
  campaignName
  featureName
  featureType
  id
  htmlContent {
    ...HtmlNodeFragment
  }
  image {
    ...ImageFragment
  }
  mobileHtmlContent {
    ...HtmlNodeFragment
  }
  mobileImage {
    ...ImageFragment
  }
  name
  targetDOMElement
  skin
  onboardingType: type
}
    `;