'use client';

import useAnimationReduced from '@haaretz/s-atoms/animationReduced';
import useArticleId from '@haaretz/s-atoms/articleId';
import useArticleType from '@haaretz/s-atoms/articleType';
import useAuthors from '@haaretz/s-atoms/authors';
import useContrastMode from '@haaretz/s-atoms/contrastMode';
import useMagazineCoverColor from '@haaretz/s-atoms/magazineCoverColor';
import usePage from '@haaretz/s-atoms/page';
import usePageType from '@haaretz/s-atoms/pageType';
import usePaywallType from '@haaretz/s-atoms/paywallType';
import usePlatform from '@haaretz/s-atoms/platform';
import usePreviewId from '@haaretz/s-atoms/previewId';
import useReadingHistory from '@haaretz/s-atoms/readingHistory';
import useReadingHistoryCount from '@haaretz/s-atoms/readingHistoryCount';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import useTags from '@haaretz/s-atoms/tags';
import useUser from '@haaretz/s-atoms/user';
import useUserAgent from '@haaretz/s-atoms/userAgent';
import useZenMode from '@haaretz/s-atoms/zenMode';
import { useSearchParams } from 'next/navigation';
import React from 'react';
import s9 from 'style9';

const c = s9.create({
  clientState: {
    backgroundColor: 'rgb(var(--c-secondary300))',
    direction: 'ltr',
  },
  li: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderBottomWidth: '1px',
    borderBottomColor: 'rgb(var(--c-primary1000))',
    borderBottomStyle: 'solid',
  },
});

export default function ClientState() {
  const animationReduced = useAnimationReduced();
  const articleId = useArticleId();
  const articleType = useArticleType();
  const authors = useAuthors();
  const contrastMode = useContrastMode();
  const magazineCoverColor = useMagazineCoverColor();
  const page = usePage();
  const pageType = usePageType();
  const paywallType = usePaywallType();
  const platform = usePlatform();
  const previewId = usePreviewId();
  const readingHistoryCount = useReadingHistoryCount();
  const renderingKind = useRenderingKind();
  const searchParams = useSearchParams();
  const tags = useTags();
  const user = useUser('cookieValue');
  const userAgent = useUserAgent();
  const zenMode = useZenMode();
  const readingHistory = useReadingHistory();

  if (!searchParams?.has('debug')) return null;

  return (
    <div className={s9(c.clientState)}>
      <h1>Client state</h1>
      <ul>
        <li className={s9(c.li)}>animationReduced: {animationReduced?.toString()}</li>
        <li className={s9(c.li)}>articleId: {articleId}</li>
        <li className={s9(c.li)}>articleType: {articleType}</li>
        <li className={s9(c.li)}>authors: {JSON.stringify(authors)}</li>
        <li className={s9(c.li)}>contrastMode: {contrastMode?.toString()}</li>
        <li className={s9(c.li)}>magazineCoverColor: {JSON.stringify(magazineCoverColor)}</li>
        <li className={s9(c.li)}>page: {JSON.stringify({ page })}</li>
        <li className={s9(c.li)}>pageType: {pageType}</li>
        <li className={s9(c.li)}>paywallType: {paywallType}</li>
        <li className={s9(c.li)}>platform:{platform}</li>
        <li className={s9(c.li)}>previewId: {previewId}</li>
        <li className={s9(c.li)}>readingHistory: {readingHistory}</li>
        <li className={s9(c.li)}>readingHistoryCount: {readingHistoryCount}</li>
        <li className={s9(c.li)}>renderingKind: {renderingKind}</li>
        <li className={s9(c.li)}>tags: {JSON.stringify(tags)}</li>
        <li className={s9(c.li)}>user: {JSON.stringify(user, null, 2)}</li>
        <li className={s9(c.li)}>userAgent: {userAgent}</li>
        <li className={s9(c.li)}>zenMode: {zenMode?.toString()}</li>
      </ul>
    </div>
  );
}
