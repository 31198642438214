'use client';

type SessionStatus = 'justCreated' | 'started';

type SessionData = {
  status: SessionStatus;
  created: number;
};

const SESSION_START_KEY = 'sessionStart';
const SESSION_START_TIME_KEY = 'sessionStartTime';

let sessionStatus: SessionStatus;
let sessionStartTime: number;
let initializedOn: string;

export default function getSessionDetails(): SessionData | null {
  if (typeof window === 'undefined' || typeof window.sessionStorage === 'undefined') {
    return null;
  }

  sessionStatus = sessionStatus ?? sessionStorage.getItem(SESSION_START_KEY);
  sessionStartTime = sessionStartTime ?? sessionStorage.getItem(SESSION_START_TIME_KEY);

  if (!sessionStatus) {
    sessionStatus = 'justCreated';
    sessionStartTime = Date.now();

    initializedOn = window.location.href;
    sessionStorage.setItem(SESSION_START_KEY, sessionStatus);
    sessionStorage.setItem(SESSION_START_TIME_KEY, `${sessionStartTime}`);
  } else if (
    sessionStatus === 'justCreated' &&
    (!initializedOn || initializedOn !== window.location.href)
  ) {
    sessionStatus = 'started';
    initializedOn = window.location.href;
    sessionStorage.setItem(SESSION_START_KEY, sessionStatus);
  }

  return {
    status: sessionStatus,
    created: sessionStartTime,
  };
}
