'use client';

import config from '@haaretz/l-config';
import useClientQuery from '@haaretz/s-client-data-hooks/useClientQuery';

type PersonalizedCampaignsResponse = {
  campaigns: number[];
};

const SERVICE_URL = `${config.get('personalization')}/marketing/get-user-marketing-campaigns`;

export default function usePersonalizedCampaigns() {
  return useClientQuery<PersonalizedCampaignsResponse>({
    cacheKey: 'rainbowPersonalizedCampaigns',
    url: SERVICE_URL,
    clientOptions: {
      cacheTime: 0,
      enabled: false,
    },
  });
}
