import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import shadow from '@haaretz/l-shadow.macro';
import space from '@haaretz/l-space.macro';
import { widthBpsInEm, untilWidthBpsInEm } from '@haaretz/l-theme-shared/lib/consts';
import typesetter from '@haaretz/l-type.macro';

const { filter: elevation } = shadow('low');

const pushWooshCssOverride =
  /**
   * Popup wrapper
   */
  '.pw-subscribe-popup.pw-position-top.pw-show{' +
  'bottom:initial;' +
  '}' +
  /**
   * Popup
   */
  '.pw-subscription-popup-inner{' +
  `background:${color('neutral200')} !important;` +
  `direction:${fork({ default: 'rtl', hdc: 'ltr' })};` +
  `max-width:${space(150)} !important;` +
  `filter:${elevation};` +
  'box-shadow:none !important;' +
  `padding: ${space(8)} ${space(18)} ${space(6)} ${space(6)};` +
  `border-bottom-right-radius:${space(3)};` +
  `border-bottom-left-radius:${space(3)};` +
  'display:flex;' +
  'flex-direction:column;' +
  `min-width:${space(100)};` +
  '}' +
  /**
   * Popup content - the text and the icon
   */
  '.pw-subscription-popup-content{' +
  'padding:0 !important;' +
  '}' +
  /**
   * Icon wrapper
   */
  '.pw-subscription-popup-icon{' +
  'position:absolute;' +
  `top:${space(9)};` +
  `right:${space(6.5)};` +
  'display:flex;' +
  `background-color:${color('neutral300')};` +
  'align-self:flex-start;' +
  'justify-content:center;' +
  'border-radius:50%;' +
  `width:${space(8)};` +
  'aspect-ratio:1;' +
  'margin-right:0 !important;' +
  '}' +
  /**
   * Icon
   */
  '.pw-subscription-popup-icon img{' +
  `width:${space(6)} !important;` +
  `height:${space(6)} !important;` +
  '}' +
  /**
   * Text - first line
   */
  '.pw-subscription-popup-text:first-line{' +
  'font-weight:700;' +
  '}' +
  /**
   * Text
   */
  '.pw-subscription-popup-text{' +
  `color:${color('neutral1200')}` +
  '}' +
  /**
   * Buttons wrapper
   */
  '.pw-subscription-popup-controls{' +
  `justify-content:${fork({ default: 'flex-end', hdc: 'space-evenly' })} !important;` +
  'flex-direction:row;' +
  `margin-top:${space(4)};` +
  `column-gap:${space(4)};` +
  'padding:0 !important;' +
  '}' +
  /**
   * Ask later btn
   * Note: this changes subscribeBtn as well due to shared classes
   */
  'button.pw-subscribe-popup-button{' +
  'transition-property:background-color,color,border-color,outline-offset,outline-width;' +
  'transition-duration:0.2s;' +
  'transition-timing-function:ease-in-out;' +
  'margin:0 !important;' +
  'padding:4px 16px !important;' +
  'display:flex !important;' +
  `font-size:${typesetter(0).fontSize} !important;` +
  `line-height:${typesetter(0).lineHeight} !important;` +
  'align-items:center;' +
  `color:${color('primary1000')} !important;` +
  'font-weight:700 !important;' +
  '}' +
  /**
   * Ask later btn hover
   */
  'button.pw-subscribe-popup-button:hover{' +
  `background:${fork({ default: color('primary200'), tm: color('primary100') })} !important;` +
  '}' +
  /**
   * Subscribe btn
   * Note: changes just subscribeBtn, overrides the pushwoosh css of this particular btn (Specificity)
   */
  'button.pw-subscribe-popup-button.pw-subscribe-popup-button-active{' +
  `background:${color('primary1000')} !important;` +
  'transition-property:background-color,color,border-color,outline-offset,outline-width;' +
  'transition-duration:0.2s;' +
  'transition-timing-function:ease-in-out;' +
  `font-size:${space(4)};` +
  'margin:0 !important;' +
  'display:flex;' +
  'align-items:center;' +
  `color:${color('bodyTextNegative')} !important;` +
  'font-weight:700 !important;' +
  '}' +
  /**
   * Subscribe btn hover
   */
  'button.pw-subscribe-popup-button.pw-subscribe-popup-button-active:hover{' +
  `background:${color('primary1200')} !important;` +
  '}' +
  /**
   * Don't show on print
   */
  '@media print{' +
  '.pw-subscribe-popup{' +
  'display:none !important;' +
  '}' +
  '}' +
  /**
   * All except for mobile
   * ```ts
   * Same as mq({from: 's', value: {...}})
   * ```
   */
  `@media all and (min-width:${widthBpsInEm.s}em){` +
  /** Icon */
  '.pw-subscription-popup-icon img{' +
  `width:${space(6)}!important;` +
  `height:${space(6)} !important;` +
  '}' +
  '}' +
  /**
   * Xxl mq
   * ```ts
   * mq({from: 'xxl', value: {...}})
   * ```
   */
  `@media all and (min-width:${widthBpsInEm.xxl}em){` +
  /**
   * Ask later btn
   */
  'button.pw-subscribe-popup-button{' +
  `font-size:${typesetter(-1).fontSize} !important;` +
  `line-height:${typesetter(-1).lineHeight} !important;` +
  '}' +
  '}' +
  /**
   * Mobile mq
   * ```ts
   * mq({until: 's', value: {...}})
   * ```
   */
  `@media all and (max-width:${untilWidthBpsInEm.s}em){` +
  /**
   * Ask later btn
   */
  'button.pw-subscribe-popup-button{' +
  `font-size:${typesetter(-2).fontSize} !important;` +
  `line-height:${typesetter(-2).lineHeight} !important;` +
  'padding:2px 12px !important;' +
  'height:auto !important;' +
  '}' +
  /**
   * Popup
   */
  '.pw-subscription-popup-inner{' +
  'min-width:initial' +
  '}' +
  /**
   * Icon wrapper
   */
  '.pw-subscription-popup-icon{' +
  `width:${space(6)} !important;` +
  '}' +
  /** Icon */
  '.pw-subscription-popup-icon img{' +
  `width:${space(4)} !important;` +
  `height:${space(4)} !important;` +
  '}' +
  '}';

export default pushWooshCssOverride;
