import type { MagazineCoverColor } from '@haaretz/s-types';

export const coverColors: MagazineCoverColor[] = [
  'athena',
  'black',
  'herra',
  'neutral',
  'venus',
  'yellow',
];

export default function isMagazineCoverColor(
  coverColor?: string | null
): coverColor is MagazineCoverColor {
  return coverColors.includes(coverColor as MagazineCoverColor);
}
