import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import s9 from 'style9';

import type { MainLogoVariant } from '../../Masthead.types';
import type { StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  searchPageLogoWrapper: {
    marginBottom: space(4),
    marginTop: space(4),
    display: 'flex',
    justifyContent: 'center',
  },
  logoWrapper: {
    display: 'flex',
    pointerEvents: 'none',
    position: 'absolute',
    height: '100%',
    left: 0,
    top: 0,
    width: '100%',
  },
  extendedLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    ...merge({
      ...mq({
        from: 'm',
        until: 'l',
        value: {
          alignSelf: 'center',
        },
      }),
    }),
  },
});

type WrapperProps = {
  children: React.ReactNode;
  variant: MainLogoVariant;
  styleExtend?: StyleExtend;
};

export default function Wrapper({ children, variant, styleExtend = [] }: WrapperProps) {
  return (
    <div
      className={s9(
        variant === 'searchPage' ? c.searchPageLogoWrapper : c.logoWrapper,
        variant === 'extended' && c.extendedLogoWrapper,
        ...styleExtend
      )}
    >
      {children}
    </div>
  );
}
