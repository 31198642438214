import FooterContent from './FooterContent';
import FooterWrapper from './FooterWrapper';

export default function WithoutData() {
  return (
    <FooterWrapper>
      <FooterContent />
    </FooterWrapper>
  );
}
