import useAuthors from '@haaretz/s-atoms/authors';
// import useCookie from '@haaretz/s-atoms/cookie';
import usePageType from '@haaretz/s-atoms/pageType';
import usePaywallType from '@haaretz/s-atoms/paywallType';
import usePlatform from '@haaretz/s-atoms/platform';
import useRenderKind from '@haaretz/s-atoms/renderingKind';
import getSessionDetails from '@haaretz/s-session-util';
import { useCallback } from 'react';

import useRainbowToolStats from '../utils/useRainbowToolStatReader';

import usePersonalizedCampaigns from './usePersonalizedCampagins';
import usePurchaseVisits from './usePurchaseVisits';
import { RainbowToolLogResponse } from './useRainbowToolStatLogger';

import type { JsonObject, JsonValue } from '@haaretz/s-types';

/**
 * Hook to collect info about the current user.
 * The hook collects the following data:
 *
 * platform: desktop | mobile | app
 * pageType: Homepage | Section | Author | <xxx>Article etc'
 * referrerUrl: url of page
 * authors?: article-page author ids array.
 * pagePaywallLevel: open | premium ...etc
 * sessionDetails: \{ status, created \}
 * personalizedCampaigns?: array of personalized-campaign ids
 * purchaseVisit?: date in millis of last visit on purchase page
 * marketingToolsStats?: Array of \{ contentId, lastExposure, exposureCount \}
 * testGroups?: \{ <test-name>: A | B \}
 *
 *
 * @returns
 */
export default function useRainbowFacts() {
  const platform = usePlatform();
  const pageType = usePageType();
  const pagePaywallLevel = usePaywallType();
  const renderingKind = useRenderKind();
  const authors = useAuthors();

  const { refetch: refetchPersonalizedCampaigns } = usePersonalizedCampaigns();
  const { refetch: refetchPurchaseVisit } = usePurchaseVisits();
  const { refetch: refetchMarketingToolsStats } = useRainbowToolStats();

  const factsFetcher = useCallback(async () => {
    const facts: JsonObject = {};

    const rainbowPageType = pageType.endsWith('Article')
      ? `${renderingKind === 'blocked' ? 'closed' : 'open'}Article`
      : pageType;

    const { data: purchaseVisit } = await refetchPurchaseVisit();
    const { data: marketingToolsStats } = await refetchMarketingToolsStats();
    const { data: personalizedCampaigns } = await refetchPersonalizedCampaigns();

    const testGroups = collectTestGroups(marketingToolsStats?.['marketing-tools-log']);
    const sessionDetails = getSessionDetails();

    setProperty(facts, 'platform', platform);
    setProperty(facts, 'pageType', rainbowPageType);
    setProperty(facts, 'pagePaywallLevel', pagePaywallLevel);
    setProperty(facts, 'referrerUrl', getPageUrl());
    setProperty(facts, 'authors', authors?.map(author => author.contentId));
    setProperty(facts, 'purchaseVisit', purchaseVisit);
    setProperty(facts, 'marketingToolsStats', marketingToolsStats?.['marketing-tools-log']);
    setProperty(facts, 'sessionDetails', sessionDetails);
    setProperty(facts, 'personalizedCampaigns', personalizedCampaigns?.campaigns);
    setProperty(facts, 'testGroups', testGroups);
    /**
     * Cookie is passed to Brightspot with every request.
     * If there is a problem you can un-comment next line to send cookie along with 'facts' object
     * */
    // setProperty(facts, 'userDetailsCookie', cookie.user_details);

    return facts;
  }, [
    authors,
    // cookie.user_details,
    pagePaywallLevel,
    pageType,
    platform,
    refetchMarketingToolsStats,
    refetchPersonalizedCampaigns,
    refetchPurchaseVisit,
    renderingKind,
  ]);

  return factsFetcher;
}

function getPageUrl(): string | null {
  let pageUrl = null;
  if (typeof window !== 'undefined') {
    pageUrl = window.location.href;
  }

  return pageUrl;
}

/**
 * Extract test-groups from rainbow-tools log data
 * @param toolsStats - rainbow-tools statistic data
 * @returns A mapping object of test-name to a group-name
 */
function collectTestGroups(toolsStats: RainbowToolLogResponse['marketing-tools-log'] | undefined) {
  if (!toolsStats || toolsStats.length === 0) {
    return null;
  }

  const groups = toolsStats
    .map(stats => stats.testGroup)
    .filter(group => !!group)
    .reduce(
      (acc, item) => {
        if (!item) {
          return acc;
        }

        const result = acc || {};

        const [key, val]: string[] = item.split(':');
        result[key] = val;

        return result;
      },
      undefined as Record<string, string> | undefined
    );

  return groups;
}

/**
 * Sets a property to an object if value is exists
 * @param object - the taget object to update
 * @param propertyName - the propertny-name to set
 * @param value - the value to set
 */
function setProperty(
  object: JsonObject,
  propertyName: string,
  value: JsonValue | null | undefined
): void {
  if (typeof value !== 'undefined' && value !== null) {
    object[propertyName] = value;
  }
}
