import { AnimationReducedPreferenceProvider } from '@haaretz/s-atoms/animationReduced';
import { ArticleIdProvider } from '@haaretz/s-atoms/articleId';
import { ArticleTypeProvider } from '@haaretz/s-atoms/articleType';
import { AuthorsProvider } from '@haaretz/s-atoms/authors';
import { ClientTeaserOnPageProvider } from '@haaretz/s-atoms/clientTeasersOnPage';
import { ContrastModeProvider } from '@haaretz/s-atoms/contrastMode';
import { CookieProvider } from '@haaretz/s-atoms/cookie';
import { CountryCodeProvider } from '@haaretz/s-atoms/countryCode';
import { MagazineCoverColorProvider } from '@haaretz/s-atoms/magazineCoverColor';
import { PageProvider } from '@haaretz/s-atoms/page';
import { PageStatusTokenProvider } from '@haaretz/s-atoms/pageStatusToken';
import { PageTypeProvider } from '@haaretz/s-atoms/pageType';
import { PaywallTypeProvider } from '@haaretz/s-atoms/paywallType';
import { PlatformProvider } from '@haaretz/s-atoms/platform';
import { PreviewIdProvider } from '@haaretz/s-atoms/previewId';
import { ReadingHistoryProvider } from '@haaretz/s-atoms/readingHistory';
import { ReadingHistoryCountProvider } from '@haaretz/s-atoms/readingHistoryCount';
import { RenderingKindProvider } from '@haaretz/s-atoms/renderingKind';
import { TagsProvider } from '@haaretz/s-atoms/tags';
import { UserProvider } from '@haaretz/s-atoms/user';
import { UserAgentProvider } from '@haaretz/s-atoms/userAgent';
import { ZenModeProvider } from '@haaretz/s-atoms/zenMode';

import type { AppGlobalType } from '@haaretz/s-data-structure-types';
import type { AtomProviderTuple } from '@haaretz/s-types';

interface AtomProviderProps {
  initialGlobalStore: AppGlobalType;
}

export default function AtomProviders({
  children,
  initialGlobalStore,
}: React.PropsWithChildren<AtomProviderProps>) {
  const initialStore: AtomProviderTuple[] = [
    // Provideders that depends on the other providers
    [ReadingHistoryCountProvider, null],
    [ReadingHistoryProvider, null],
    [ZenModeProvider, initialGlobalStore.zenMode],

    // Standalone providers
    [AnimationReducedPreferenceProvider, null],
    [ArticleIdProvider, initialGlobalStore.articleId] as AtomProviderTuple,
    [ArticleTypeProvider, initialGlobalStore.articleType] as AtomProviderTuple,
    [AuthorsProvider, initialGlobalStore.authors] as AtomProviderTuple,
    [ClientTeaserOnPageProvider, null],
    [ContrastModeProvider, null],
    [CookieProvider, null],
    [CountryCodeProvider, initialGlobalStore.countryCode] as AtomProviderTuple,
    [MagazineCoverColorProvider, initialGlobalStore.magazineCoverColor] as AtomProviderTuple,
    [PageProvider, initialGlobalStore.page] as AtomProviderTuple,
    [PageStatusTokenProvider, initialGlobalStore.pageStatusToken] as AtomProviderTuple,
    [PageTypeProvider, initialGlobalStore.pageType] as AtomProviderTuple,
    [PaywallTypeProvider, initialGlobalStore.paywallType] as AtomProviderTuple,
    [PlatformProvider, initialGlobalStore.platform] as AtomProviderTuple,
    [PreviewIdProvider, initialGlobalStore.previewId] as AtomProviderTuple,
    [RenderingKindProvider, initialGlobalStore.renderingKind] as AtomProviderTuple,
    [TagsProvider, initialGlobalStore.tags] as AtomProviderTuple,
    [UserAgentProvider, null],
    [UserProvider, initialGlobalStore.user] as AtomProviderTuple,
  ];

  let providers = children;

  for (const [Provider, value] of initialStore) {
    providers = <Provider value={value}>{providers}</Provider>;
  }

  return <>{providers}</>;
}
