'use client';

import React from 'react';

const UserAgentContext = React.createContext<string | null | undefined>(undefined);

export function UserAgentProvider({ children }: React.PropsWithChildren) {
  const [userAgent, setUserAgent] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      setUserAgent(navigator.userAgent);
    }
  }, []);

  return <UserAgentContext.Provider value={userAgent}>{children}</UserAgentContext.Provider>;
}

export default function useUserAgent() {
  const context = React.useContext(UserAgentContext);

  if (context === undefined) {
    throw new Error('useUserAgent must be used within a UserAgentProvider');
  }

  return context;
}
