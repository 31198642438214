/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import type { ThemeColor, WidthBpName } from '@haaretz/l-theme-shared/types';
import type { StyleExtend, InlineStyles, WithAttrPropsAndAs } from '@haaretz/s-types';

const classNames = s9.create({
  base: {
    '--contentWidth': 'minmax(0, 1fr)',
    '--LayoutContainerGutter': space(3),
    '--inlineMargin': 'auto',
    '--_lcbgc': 'var(--LayoutContainerBackgroundColor, rgba(0,0,0,0))',
    '--lcRowGap': `var(--childSpacingDefault, ${space(10)})`,
    backgroundColor: 'var(--_lcbgc)',
    display: 'grid',
    gridTemplateColumns:
      '[bleed-start] var(--inlineMargin)' +
      '[gutter-start] var(--LayoutContainerGutter)' +
      '[main-start] var(--contentWidth) [main-end]' +
      'var(--LayoutContainerGutter) [gutter-end]' +
      'var(--inlineMargin) [bleed-end]',
    rowGap: 'var(--lcRowGap)',
    width: '100%',

    ...merge(
      mq({
        from: 's',
        until: 'xxl',
        value: {
          '--inlineMargin': 'minmax(0, 1fr)',
        },
      }),
      mq({
        from: 's',
        until: 'm',
        value: {
          '--contentWidth': `minmax(0, ${space(150 - 12)})`, // 150 is 600px
          '--LayoutContainerGutter': space(6),
          '--lcRowGap': `var(--childSpacingS, ${space(10)})`,
        },
      }),
      mq({
        from: 'm',
        until: 'l',
        value: {
          '--contentWidth': `minmax(0, ${space(192 - 12)})`, // 192 is 768px
          '--LayoutContainerGutter': space(6),
          '--lcRowGap': `var(--childSpacingM, ${space(10)})`,
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          '--contentWidth': `minmax(0, ${space(256 - 12)})`, // 256 is 1024px
          '--LayoutContainerGutter': space(6),
          '--lcRowGap': `var(--childSpacingL, ${space(10)})`,
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          '--contentWidth': `minmax(0, ${space(320 - 14)})`, // 320 is 1280px
          '--LayoutContainerGutter': space(7),
          '--lcRowGap': `var(--childSpacingXl, ${space(14)})`,
        },
      }),
      mq({
        from: 'xxl',
        value: {
          '--contentWidth': `minmax(0, ${space(400 - 14)})`, // 400 is 1600px
          '--LayoutContainerGutter': space(7),
          '--lcRowGap': `var(--childSpacingXxl, var(--childSpacingXl, ${space(14)}))`,
        },
      })
    ),
  },
});

type SpacingValue = `${number}rem`;
type ChildSpacing = {
  [key in WidthBpName | 'default']?: SpacingValue;
};
type SpacingBps = keyof ChildSpacing;

export type AllowedElements =
  | 'article'
  | 'aside'
  | 'div'
  | 'footer'
  | 'header'
  | 'main'
  | 'nav'
  | 'p'
  | 'section';

interface LayoutContainerOwnProps<As extends AllowedElements> {
  /**
   * The HTML element (`string`) or React component
   * the `<LayoutContainer />` will be rendered as.
   */
  as?: As;
  /**
   * A color value for the background of the entire row, which
   * will bleed out all the way to the edge of the screen (so not
   * just the contained layout area). Must be the output of the `color` macro.
   */
  bgc?: ThemeColor;
  /** The Children to render inside the `<LayoutContainer>` */
  children?: React.ReactNode;
  /**
   * Changes the default vertical spacing between *children*
   * of the container (e.g., the space between list views on the Homepage)
   *
   * @remarks
   * * Internal vertical spacing between the container's children
   *   is set using the `row-gap` property. This means that an
   *   individual child element can override the spacing around it
   *   by using top and bottom margins (including negative margins)
   * * Takes an object with an (optional) value for each breakpoint,
   *   meaning it's possible to change the default vertical spacing
   *   only for one or several specific breakpoints.
   *
   * @example
   * ```ts
   * import space from '@haaretz/l-space.macro';
   * import s9 from 'style9';
   * function MyLayoutContainer(props) {
   *   // This will only affect the default spacing
   *   // between the `l` and `xl` breakpoints
   *   return <LayoutContainer childSpacing={{ l: space(8) }}/>;
   * }
   * ```
   */
  childSpacing?: ChildSpacing;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyLayoutContainer(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <LayoutContainer
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <LayoutContainer styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

export const DEFAULT_ELEMENT = 'div';
export type DefaultElement = typeof DEFAULT_ELEMENT;

export type LayoutContainerProps<As extends AllowedElements = DefaultElement> = WithAttrPropsAndAs<
  LayoutContainerOwnProps<As>,
  As
>;

export default function LayoutContainer<As extends AllowedElements = DefaultElement>(
  props: LayoutContainerProps<As>
) {
  const {
    as = DEFAULT_ELEMENT,
    bgc,
    children = null,
    inlineStyle,
    childSpacing,
    styleExtend = [],
    className,
    ...attrs
  } = props;

  const Element: AllowedElements = as;

  //////////////////////
  //  Dynamic Styles  //
  //////////////////////
  const backgroundColor = bgc
    ? { '--_lcbgc': `var(--LayoutContainerBackgroundColor, ${bgc})` }
    : {};
  const spacingVars: Record<string, SpacingValue> = {};
  if (childSpacing) {
    for (const bp of Object.keys(childSpacing) as SpacingBps[]) {
      const value = childSpacing[bp];
      if (value) spacingVars[`--childSpacing${bp[0].toUpperCase() + bp.slice(1)}`] = value;
    }
  }

  return (
    <Element
      className={(className ? `${className} ` : '') + s9(classNames.base, ...styleExtend)}
      style={{
        ...backgroundColor,
        ...spacingVars,
        ...inlineStyle,
      }}
      {...attrs}
    >
      {children}
    </Element>
  );
}
