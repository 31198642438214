import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import Button from '@haaretz/s-button';
import HdcLogo from '@haaretz/s-hdc-logo';
import HtzLogo from '@haaretz/s-htz-logo';
import MarkerLogo from '@haaretz/s-marker-logo';
import s9 from 'style9';

import SocialLinks from '../Footer/SocialLinks';

const c = s9.create({
  logoWrapper: {
    justifyContent: 'center',
    fontSize: space(7),
    ...merge(
      mq({ until: 's', value: { marginBottom: space(8) } }),
      mq({ from: 's', until: 'l', value: { fontSize: space(9) } }),
      mq({ from: 'l', value: { fontSize: space(10) } })
    ),
  },
  headerWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    ...merge(
      mq({
        from: 's',
        value: {
          alignItems: 'end',
          justifyContent: 'space-between',
          flexDirection: 'row',
          ...border({
            color: color('footerColor'),
            spacing: 4,
            style: 'solid',
            width: '1px',
            side: 'bottom',
          }),
        },
      })
    ),
  },
  mobileExclude: {
    ...mq({ until: 's', value: { display: 'none' } }),
  },
  desktopExclude: {
    ...mq({ from: 's', value: { display: 'none' } }),
  },
});

export default function FooterWrapper() {
  return (
    <div className={s9(c.headerWrapper)}>
      <div className={s9(c.logoWrapper)}>
        {fork({
          default: <HtzLogo />,
          tm: <MarkerLogo />,
          hdc: <HdcLogo />,
        })}
      </div>
      <SocialLinks styleExtend={[c.mobileExclude]} />
      <Button
        as="a"
        href={fork({
          default: 'https://kg3g3.app.goo.gl/4UAu',
          tm: 'https://bykf9.app.goo.gl/1PnF',
          hdc: 'https://y7duh.app.goo.gl/wnG6',
        })}
        styleExtend={[c.desktopExclude]}
      >
        {fork({ default: 'להורדת האפליקציה', hdc: 'Download App' })}
      </Button>
    </div>
  );
}
