'use client';

import articleTypeValidator from '@haaretz/s-validators/articleTypeValidator';
import React from 'react';

import type { ArticleType } from '@haaretz/s-types';

const ArticleTypeContext = React.createContext<ArticleType | null | undefined>(undefined);
const ArticleTypeSetter = React.createContext<((articleType: ArticleType) => void) | undefined>(
  undefined
);

export function ArticleTypeProvider({
  children,
  value: defaultArticleType,
}: React.PropsWithChildren<{ value: ArticleType | null }>) {
  const [articleType, setArticleType] = React.useState<ArticleType | null>(defaultArticleType);

  const articleTypeSetter = React.useCallback((value: ArticleType) => {
    if (typeof value !== 'string' || value.trim() === '') {
      setArticleType('StandardArticle');
    } else {
      setArticleType(articleTypeValidator(value) ? value : 'StandardArticle');
    }
  }, []);

  return (
    <ArticleTypeContext.Provider value={articleType}>
      <ArticleTypeSetter.Provider value={articleTypeSetter}>{children}</ArticleTypeSetter.Provider>
    </ArticleTypeContext.Provider>
  );
}

export default function useArticleType() {
  const context = React.useContext(ArticleTypeContext);

  if (context === undefined) {
    throw new Error('useArticleType must be used within a ArticleTypeProvider');
  }

  return context;
}

export function useSetArticleType() {
  const context = React.useContext(ArticleTypeSetter);

  if (context === undefined) {
    throw new Error('useSetArticleType must be used within a ArticleTypeProvider');
  }

  return context;
}

export function useArticleTypeAtom() {
  return [useArticleType(), useSetArticleType()] as const;
}
