import { useGetMarketingDataPersonalQuery } from '@haaretz/s-queries/Rainbow';
import * as React from 'react';

import type { CommonPageLayoutFragment } from '@haaretz/s-fragments/CommonPageLayout';
import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';

interface RainbowToolsProviderProps extends Pick<CommonPageLayoutFragment, 'defaultRainbowTools'> {
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setTools: React.Dispatch<React.SetStateAction<RainbowToolFragment[]>>;
  facts?: string;
  slotsSet?: Set<string>;
  children?: React.ReactNode;
}
export default function RainbowToolsProvider({
  setTools,
  setIsLoaded,
  facts,
  slotsSet,
  defaultRainbowTools,
  children,
}: RainbowToolsProviderProps) {
  const slots = slotsSet && [...slotsSet];
  const shouldFetch = !!(facts && slotsSet?.size);

  const { data: rainbowTools } = useGetMarketingDataPersonalQuery(
    { facts, slots },
    { enabled: shouldFetch }
  );

  React.useEffect(() => {
    const toolsToRender = rainbowTools?.Rainbow?.tools;

    if (toolsToRender?.length) {
      setTools(toolsToRender);
      setIsLoaded(true);
    }
  }, [defaultRainbowTools, facts, rainbowTools?.Rainbow?.tools, setIsLoaded, setTools]);

  return children;
}
