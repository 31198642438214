'use client';

import isMagazineCoverColor from '@haaretz/s-type-predicates/isMagazineCoverColor';
import React from 'react';

import type { MagazineCoverColor } from '@haaretz/s-types';

const MagazineCoverColorContext = React.createContext<MagazineCoverColor | null | undefined>(
  undefined
);
const MagazineCoverColorSetter = React.createContext<
  ((value?: string | null | undefined) => void) | undefined
>(undefined);

/**
 * NOTE: It's very important to import getMatchMediaString not from the root of the `@haaretz/l-theme-shared` package,
 * because we don't want to include all the package code into the client bundle.
 */
export function MagazineCoverColorProvider({
  children,
  value: defaultMagazineCoverColor,
}: React.PropsWithChildren<{ value: MagazineCoverColor | null }>) {
  const [magazineCoverColor, setMagazineCoverColor] = React.useState<MagazineCoverColor | null>(
    defaultMagazineCoverColor
  );

  const magazineCoverColorSetter = React.useCallback((value?: string | undefined | null) => {
    setMagazineCoverColor(isMagazineCoverColor(value) ? value : null);
  }, []);

  return (
    <MagazineCoverColorContext.Provider value={magazineCoverColor}>
      <MagazineCoverColorSetter.Provider value={magazineCoverColorSetter}>
        {children}
      </MagazineCoverColorSetter.Provider>
    </MagazineCoverColorContext.Provider>
  );
}

export default function useMagazineCoverColor() {
  const context = React.useContext(MagazineCoverColorContext);

  if (context === undefined) {
    throw new Error('useMagazineCoverColor must be used within a MagazineCoverColorProvider');
  }

  return context;
}

export function useSetMagazineCoverColor() {
  const context = React.useContext(MagazineCoverColorSetter);

  if (context === undefined) {
    throw new Error('useSetMagazineCoverColor must be used within a MagazineCoverColorProvider');
  }

  return context;
}

export function useMagazineCoverColorAtom() {
  return [useMagazineCoverColor(), useSetMagazineCoverColor()] as const;
}
