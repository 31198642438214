import * as Types from '@haaretz/s-fragments/Types';

import { RainbowToolFragmentGql } from '@haaretz/s-fragments/RainbowTool';
import { MultiTypeMarketingElementFragmentGql } from '@haaretz/s-fragments/MultiTypeMarketingElement';
import { HtmlNodeFragmentGql } from '@haaretz/s-fragments/HTMLNode';
import { ImageFragmentGql } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragmentGql } from '@haaretz/s-fragments/ImageCrop';
import { OnboardingElementFragmentGql } from '@haaretz/s-fragments/OnboardingElement';
import { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';
import { MultiTypeMarketingElementFragment } from '@haaretz/s-fragments/MultiTypeMarketingElement';
import { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
import { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';
import { OnboardingElementFragment } from '@haaretz/s-fragments/OnboardingElement';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type GetMarketingDataPersonalQueryVariables = Types.Exact<{
  facts?: Types.InputMaybe<Types.Scalars['String']>;
  slots?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetMarketingDataPersonalQuery = (
  { Rainbow?: (
    { tools?: Array<(
      RainbowToolFragment
      & { __typename: 'RainbowTool' }
    )> | null }
    & { __typename: 'RainbowToolsSearch' }
  ) | null }
  & { __typename: 'Query' }
);


export const GetMarketingDataPersonalQueryDocument = `
    query GetMarketingDataPersonalQuery($facts: String, $slots: [String]) {
  Rainbow(facts: $facts, slots: $slots) {
    __typename
    tools {
      __typename
      ...RainbowToolFragment
    }
  }
}
    ${RainbowToolFragmentGql}
${MultiTypeMarketingElementFragmentGql}
${HtmlNodeFragmentGql}
${ImageFragmentGql}
${ImageCropFragmentGql}
${OnboardingElementFragmentGql}`;
export const useGetMarketingDataPersonalQuery = <
      TData = GetMarketingDataPersonalQuery,
      TError = Error
    >(
      variables?: GetMarketingDataPersonalQueryVariables,
      options?: UseQueryOptions<GetMarketingDataPersonalQuery, TError, TData>
    ) =>
    useQuery<GetMarketingDataPersonalQuery, TError, TData>(
      variables === undefined ? ['GetMarketingDataPersonalQuery'] : ['GetMarketingDataPersonalQuery', variables],
      fetch<GetMarketingDataPersonalQuery, GetMarketingDataPersonalQueryVariables>(GetMarketingDataPersonalQueryDocument, variables),
      options
    );
useGetMarketingDataPersonalQuery.document = GetMarketingDataPersonalQueryDocument;


useGetMarketingDataPersonalQuery.getKey = (variables?: GetMarketingDataPersonalQueryVariables) => variables === undefined ? ['GetMarketingDataPersonalQuery'] : ['GetMarketingDataPersonalQuery', variables];
;

export const useInfiniteGetMarketingDataPersonalQuery = <
      TData = GetMarketingDataPersonalQuery,
      TError = Error
    >(
      pageParamKey: keyof GetMarketingDataPersonalQueryVariables,
      variables?: GetMarketingDataPersonalQueryVariables,
      options?: UseInfiniteQueryOptions<GetMarketingDataPersonalQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GetMarketingDataPersonalQuery, TError, TData>(
      variables === undefined ? ['GetMarketingDataPersonalQuery.infinite'] : ['GetMarketingDataPersonalQuery.infinite', variables],
      (metaData) => fetch<GetMarketingDataPersonalQuery, GetMarketingDataPersonalQueryVariables>(GetMarketingDataPersonalQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(metaData),
      options
    )};


useInfiniteGetMarketingDataPersonalQuery.getKey = (variables?: GetMarketingDataPersonalQueryVariables) => variables === undefined ? ['GetMarketingDataPersonalQuery.infinite'] : ['GetMarketingDataPersonalQuery.infinite', variables];
;

useGetMarketingDataPersonalQuery.fetcher = (variables?: GetMarketingDataPersonalQueryVariables, options?: RequestInit['headers']) => fetch<GetMarketingDataPersonalQuery, GetMarketingDataPersonalQueryVariables>(GetMarketingDataPersonalQueryDocument, variables, options);