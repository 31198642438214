'use client';

import usePreviewId from '@haaretz/s-atoms/previewId';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import useUser from '@haaretz/s-atoms/user';
import * as React from 'react';

import useRainbowFacts from '../../facts/useRainbowFacts';
import useIsRainbowEnabled from '../../utils/useIsRainbowEnabled/useIsRainbowEnabled';

import RainbowToolsProvider from './RainbowToolsProvider';

import type { CommonPageLayoutFragment } from '@haaretz/s-fragments/CommonPageLayout';
import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';

export interface RainbowDataProviderProps
  extends Pick<CommonPageLayoutFragment, 'defaultRainbowTools' | 'useDefaultOnlyForAnonymous'> {
  /** The Children to be rendered inside `<RainbowDataProvider>` */
  children?: React.ReactNode;
}

export interface RainbowContextType {
  /** Tools fetched from server */
  tools: RainbowToolFragment[];
  /** Rainbow slots defined on page */
  slots: Set<string>;
  /** default tools to use if nothing fetched from server */
  defaultTools: RainbowToolFragment[];
  /** true when request for Rainbow tools is comleted */
  isLoaded: boolean;
}

// RainbowTools context is cunstructed of [ RainbowTool[], slotId[], isLoaded ]
export const RainbowToolsContext = React.createContext<RainbowContextType>({
  tools: [],
  slots: new Set(),
  defaultTools: [],
  isLoaded: false,
});

export default function RainbowDataProvider({
  defaultRainbowTools,
  children,
}: RainbowDataProviderProps) {
  const previewId = usePreviewId();
  const user = useUser('cookieValue');
  const [tools, setTools] = React.useState<RainbowToolFragment[]>([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const isRainbowEnabled = useIsRainbowEnabled();
  const renderingKind = useRenderingKind();
  const isBot = renderingKind === 'expanded';

  const slots = React.useRef(new Set<string>()); // The slots defined in this Provider scope.

  const [facts, setFacts] = React.useState<string>();
  const factsFetcher = useRainbowFacts();

  React.useEffect(() => {
    // rainbow data fetcher definition.
    // The execution is at the end of this useEffect function
    const executeFactsFetcher = async () => {
      const data = await factsFetcher();
      if (data) {
        setFacts(JSON.stringify(data));
      }
    };

    // test if fetch shoulld run
    const isShouldFetch = !!(
      !isBot &&
      isRainbowEnabled &&
      slots.current.size > 0 &&
      user.anonymousId &&
      user.userType
    );

    // Executing rainbow data fetcher
    isShouldFetch && executeFactsFetcher();
  }, [factsFetcher, isBot, isRainbowEnabled, user.anonymousId, user.userType]);

  const ctxVal: RainbowContextType = React.useMemo(
    () => ({
      tools,
      defaultTools: defaultRainbowTools || [],
      slots: slots.current,
      isLoaded,
    }),
    [defaultRainbowTools, isLoaded, tools]
  );

  return previewId ? (
    children
  ) : (
    <RainbowToolsContext.Provider value={ctxVal}>
      <RainbowToolsProvider
        facts={facts}
        slotsSet={slots.current}
        setTools={setTools}
        setIsLoaded={setIsLoaded}
      >
        {children}
      </RainbowToolsProvider>
    </RainbowToolsContext.Provider>
  );
}
