import { COLOR_PROP_PREFIX, DEFAULT_CUSTOM_PROP_SELECTOR } from './consts';

import type { BaseColor, ColorAlias, SitePalette } from '../scale/colorPalette';
import type { BaseColorValue, ValidColor } from '../types/color';
import type { NonOptionalKeys, OptionalKeys } from '@haaretz/s-types';

type UsageRecord<T> = Array<T> | undefined;

/**
 * Name of a base-color custom props
 *
 * @public
 */
export type BaseColorVar = `${typeof COLOR_PROP_PREFIX}${Exclude<ValidColor, ColorAlias>}`;

/**
 * Name of a color-alias custom props
 *
 * @public
 */
export type ColorAliasVar = `${typeof COLOR_PROP_PREFIX}${Exclude<ValidColor, BaseColor>}`;

/**
 * CSS reference to a base color custom prop
 *
 * @public
 */
export type BaseColorReference = `var(${BaseColorVar})`;

/**
 * Name of a valid color custom prop
 *
 * @public
 */
export type ColorVar = `${typeof COLOR_PROP_PREFIX}${ValidColor}`;

/**
 * Mandatory color modes in a color palette
 *
 * @public
 */
export type MandatoryThemeMode = NonOptionalKeys<SitePalette>;

/**
 * Optional color modes in a color palette
 *
 * @public
 */
export type OptionalThemeKeys = OptionalKeys<SitePalette>;

/**
 * A Record of all used custom props and their values as CSS strings
 *
 * @public
 */
export type UsedCustomProps = Record<MandatoryThemeMode, string> &
  Partial<Record<OptionalThemeKeys, string>>;

/**
 * An options object for `generateColorCustomProps`
 *
 * @param theme - A site's color pallete
 * @param usageRecords - Color custom props that were used thoughout the app
 * @param generateAll - force-generate all custom props, not just used ones
 *
 * @public
 */
type Options = {
  theme: SitePalette;
} & ({ generateAll: true } | { usageRecords: UsageRecord<string> });

/**
 * Generate a Record of all used color custom props and their values as CSS strings
 *
 * @param options - an options object
 *
 * @public
 */

export default function generateColorCustomProps(options: Options): UsedCustomProps {
  type Store = {
    baseColors: Partial<{ [key in BaseColorVar]: BaseColorValue }>;
    aliases: Partial<{ [key in ColorAliasVar]: BaseColorReference }>;
  };

  const { theme } = options;
  const generateAll = 'generateAll' in options && options.generateAll;
  const record = 'usageRecords' in options ? options.usageRecords : [];

  const colorThemes: UsedCustomProps = { [DEFAULT_CUSTOM_PROP_SELECTOR]: '' };

  for (const themeName of Object.keys(theme) as Array<keyof SitePalette>) {
    const colors = theme[themeName];
    if (!colors) continue;

    const store: Store = {
      baseColors: {},
      aliases: {},
    };

    for (const [colorName, value] of Object.entries(colors || {}) as [ColorVar, string][]) {
      if (generateAll || (record && record.includes(colorName))) {
        if (isAliasValue(value)) {
          const customProp = makeColorVar(colorName as ColorAlias);
          store.aliases[customProp] = value;

          // Ensure that the custom prop of the base color that the alias
          // points to is also generated, even if it isn't enabled directly
          // somewhere else
          const baseColorName = value.slice(
            // start of actual name
            value.indexOf(COLOR_PROP_PREFIX) + COLOR_PROP_PREFIX.length,
            // Remove closing parenthesis
            -1
          ) as BaseColor;
          const baseColorValue = colors[baseColorName];
          if (isBaseColorValue(baseColorValue)) {
            const baseColorCustomProp = makeColorVar(baseColorName);
            store.baseColors[baseColorCustomProp] = baseColorValue;
          }
        } else if (isBaseColorValue(value)) {
          const customProp = makeColorVar(colorName as BaseColor);
          store.baseColors[customProp] = value;
        }
      }
    }

    // Ensure aliases come after base colors
    const cssString = `${[
      ...Object.entries(store.baseColors).sort(sortColorEntries),
      ...Object.entries(store.aliases).sort(sortColorEntries),
    ]
      .map(entry => entry.join(':'))
      .join(';')};`;

    colorThemes[themeName] = cssString;
  }

  return colorThemes;
}

/////////////
//  UTILS  //
/////////////

function sortColorEntries(a: [string, string], b: [string, string]): -1 | 0 | 1 {
  return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
}

function makeColorVar(name: BaseColor): BaseColorVar;
function makeColorVar(name: ColorAlias): ColorAliasVar;
function makeColorVar(name: BaseColor | ColorAlias): BaseColorVar | ColorAliasVar {
  return `${COLOR_PROP_PREFIX}${name}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isBaseColorValue(x: any): x is BaseColorValue {
  return /^\d{1,3},\d{1,3},\d{1,3}$/i.test(x);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAliasValue(x: any): x is BaseColorReference {
  return x.startsWith(`var(${COLOR_PROP_PREFIX}`);
}
