import config from '@haaretz/l-config';
import useClientQuery from '@haaretz/s-client-data-hooks/useClientQuery';

import type { RainbowToolLogResponse } from '../facts/useRainbowToolStatLogger';

export default function useRainbowToolStats() {
  // url for reading rainbow-tools exposure logs
  const STATS_READER_URL = `${config.get('personalization')}/marketing/get-marketing-tools-log`;

  return useClientQuery<RainbowToolLogResponse>({
    url: STATS_READER_URL,
    cacheKey: 'rainbowToolsStatistics',
    clientOptions: {
      cacheTime: 0,
      enabled: false,
    },
  });
}
