import { cssReset } from '@haaretz/l-css-reset';
import {
  colorPalette,
  defaultTypeConfig,
  generateColorCustomProps,
  generateTypographyCustomProps,
  mergeCssRulesets,
} from '@haaretz/l-theme-shared';

import type { TypographyConfig } from '@haaretz/l-theme-shared';
import type { Site } from '@haaretz/s-types';

/**
 * Configuration object for creating a custom Next `Document`
 *
 * @public
 */
export interface Options {
  /**
   * The {@link @haaretz/s-types#Site | site} being created.
   * Determines which theme, etc. will be used
   */
  site: Site;
  /**
   * The {@link @haaretz/l-theme-shared#TypographyConfig | configuration object}
   * used for setting typography.
   *
   * @defaultValue `{@link @haaretz/l-theme-shared#TypographyConfig | defaultTypeConfig}`
   */
  typeConfig?: TypographyConfig;
}

/**
 * Configuration object for creating a custom Next `Document`
 *
 * @public
 */
export default function GlobalStyles({ site, typeConfig = defaultTypeConfig }: Options) {
  if (process.env.NODE_ENV === 'production') return null;

  ////////////////////////////////////
  //  GLOBAL CSS                    //
  //  (reset and custom props)      //
  //  ------------------------      //
  //  Only needed in dev, since     //
  //  it will be added to extracted //
  //  styles in prod builds         //
  ////////////////////////////////////

  // 1.
  // Generate color custom props
  const colorCustomProps = generateColorCustomProps({
    theme: colorPalette[site],
    generateAll: true,
  });

  // 2.
  // Generate typography custom props
  const typographyCustomProps = generateTypographyCustomProps({
    typeConfig,
    generateAll: true,
  });

  // 3.
  // Merge custom props together into a single css string
  const mergedCustomPropsCssString = mergeCssRulesets(colorCustomProps, typographyCustomProps);

  // 4.
  // Merge All global CSS together
  const globalCssString = mergedCustomPropsCssString + cssReset;
  const globalCss = (
    <style
      data-info="global"
      dangerouslySetInnerHTML={{ __html: globalCssString }}
      key="global-css"
    />
  );
  return <>{globalCss}</>;
}
