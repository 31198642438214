import { pxToRem } from '../lib/pxToRem';

import type { CustomPropNumericValue, TypeScaleOpts } from '../types/typography';

/**
 * The typographic properties of an item in a typographic scale.
 * @public
 */
export type TypeProps = {
  /** The `font-size` in pixels */
  fontSize: number;
  /** The `line-height` in pixels */
  lineHeight: number;
  /** The line-height as a multiplier of the font-size */
  relativeLineHeight: number;
  /** The number of rhythm lines the line-height occupies */
  lines: number;
};

/**
 * Options object for the `getTypeProps` function
 *
 * @public
 */
export type GetTypePropsOpts = {
  /**
   * The step in the scale to get. **The typographic scale is 0 based, so `0`
   * here will return the number set in base**
   */
  step: number;
} & TypeScaleOpts;

/**
 * A CSS-in-JS object of typographic styles conforming to a global predefined
 * typographic scale and vertical rhythm.
 *
 * @public
 */
export type TypographicStyles = {
  /**
   * A font-size in rem units based on the global typographic scale configuration.
   */
  fontSize: CustomPropNumericValue;
  /**
   * A line-height in rem units based on the global vertical rhythm configuration.
   */
  lineHeight: CustomPropNumericValue;
};

/**
 * Get the properties of an item in a typographic scale.
 *
 * @returns
 *   The typographic properties of an item in a typographic scale.
 *
 * @internal
 */
export default function getTypeProps({
  step,
  base,
  minPadding,
  ratio,
  rhythmUnit,
  steps,
}: GetTypePropsOpts): TypographicStyles {
  if (typeof step !== 'number') {
    throw new Error(`"step" must be a number. You passed ${step} (${typeof step})`);
  }
  const oneRemInPx = rhythmUnit * 4;
  const fontSizeInPx = getFontSize(step, base, ratio, steps);
  const lineHeightInPx = getLineHeight(fontSizeInPx, rhythmUnit, minPadding);
  const fontSize: CustomPropNumericValue = `${pxToRem(oneRemInPx, fontSizeInPx)}rem`;
  const lineHeight: CustomPropNumericValue = `${pxToRem(oneRemInPx, lineHeightInPx)}rem`;

  return { fontSize, lineHeight };
}

/**
 * Get a font size based on the properties of a typographic scale
 *
 * @param step - The step in the scale to get (**0 base**)
 * @param base - The `font-size` at the base of the scale
 * @param ratio -
 *   The typographic scale ratio. The multiplier by which the scaling
 *   property of the scale is determined.
 *
 *   If `x` is a size in the typographic scale, than `x * ratio`
 *   will also be size in the ratio, and will occur after `n` steps, as
 *   defined in `steps`.
 * @param steps - The number of steps in between intervals of the scale.
 *
 *   If `x` is a size in the scale, it will be incremented to `x * ratio` after
 *   the specified number of steps.
 *
 * @returns
 *   A font size, in pixels, based on the properties of a typographic scale
 *
 * @remarks
 * See {@link http://spencermortensen.com/articles/typographic-scale | The Typographic Scale}
 *
 * @internal
 */
export function getFontSize(step: number, base: number, ratio: number, steps: number): number {
  // eslint-disable-next-line no-mixed-operators
  return Math.round(base * ratio ** (step / steps));
}

/**
 * Get a line-height in pixels that conforms
 * to a vertical rhythm for a specific font-size
 *
 * @param fontSize - The `font-size`, in pixels, for which to calculate `line-height`.
 * @param rhythmUnit - The basic vertical rhythm unit used to calculate `line-height`.
 * @param minPadding -
 *   The minimal amount of padding (in pixels) each line should have below and
 *   above the text. A `font-size`'s `line-height` must, at minimum, conform to:
 *   `(lineHeight - fontSize) / 2  >= minPadding`.
 *
 * @returns
 *   The `line-height` in pixels.
 *
 * @internal
 */
function getLineHeight(fontSize: number, rhythmUnit: number, minPadding: number): number {
  const fontSizeLineSpan = Math.ceil(fontSize / rhythmUnit);
  const lines = getLinewsithPadding(fontSize, fontSizeLineSpan, rhythmUnit, minPadding);

  return lines * rhythmUnit;
}

/**
 * Determine the number of rhythm-lines a font-size's line-height
 * should be, while retaining minimal padding above and below.
 *
 * @param fontSize - The `font-size` being tested.
 * @param lines - The number of lines to test padding against.
 * @param rhythmUnit -
 *   The basic unit used to set the vertical rhythm to which the
 *   `line-height` should conform.
 * @param minPadding -
 *   The minimal amount of padding (in pixels) each line should have below and
 *   above the text. A `font-size`'s `line-height` must, at minimum, conform to:
 *   `(lineHeight - fontSize) / 2  >= minPadding`.
 *
 * @returns
 *   The number of rhythm-lines a font-size's line-height
 *   should be given minimal vertical padding.
 *
 * @internal
 */
function getLinewsithPadding(
  fontSize: number,
  lines: number,
  rhythmUnit: number,
  minPadding: number
): number {
  const MAX_RATE = 1.7;

  // Ensure there is enough padding
  // prettier-ignore
  const addPadding = ((lines * rhythmUnit) - fontSize) < (minPadding * 2)
    // But that the difference between line-height and font-size
    // doesn't exceed a maximum rate.
    && (((lines + 1) * rhythmUnit) / fontSize) <= MAX_RATE;

  return addPadding ? getLinewsithPadding(fontSize, lines + 1, rhythmUnit, minPadding) : lines;
}
