import type { Site } from '@haaretz/s-types';

/**
 * A site's `z-index` tokens
 *
 * @public
 */
export interface SiteZIndices {
  below: -1;
  above: 1;
  drawer: number;
  dialogBackdrop: number;
  dialog: number;
  masthead: number;
  dropdown: number;
  alert: number;
  tooltip: number;
  modal: number;
}

/**
 * A `z-index` token name
 *
 * @public
 */
export type ZIndexName = keyof SiteZIndices;

/**
 * All sites' `z-index` tokens
 *
 * @public
 */
export type ZIndices = {
  readonly [key in Site]: Readonly<SiteZIndices>;
};

/**
 * The default named `border-radius` values for a site
 *
 * @remarks
 *   Uses rem units in order to scale with the base font size.
 *
 * @public
 */
export const defaultSiteZIndices: SiteZIndices = {
  below: -1,
  above: 1,
  drawer: 700,
  dialogBackdrop: 799,
  dialog: 800,
  masthead: 900,
  dropdown: 1000,
  alert: 1000,
  tooltip: 1100,
  modal: 1200,
} as const;

/**
 * All sites' border radius tokens
 *
 * @public
 */
export const zIndices: ZIndices = {
  hdc: defaultSiteZIndices,
  htz: defaultSiteZIndices,
  tm: defaultSiteZIndices,
} as const;
