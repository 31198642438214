import type { Site } from '@haaretz/s-types';

/**
 * A site's font stack
 * @public
 */
export interface SiteFontStacks {
  /** The primary font stack */
  primary: string;
  /** The secondary font stack */
  secondary: string;
  /** The font stack used in commercial typography */
  commercial: string;
}

/**
 * All sites font stacks
 * @public
 */
export type FontStacks = {
  readonly [key in Site]: Readonly<SiteFontStacks>;
};

/**
 * Font stack name
 * @public
 */
export type FontStackName = keyof SiteFontStacks;

/**
 * The base sanserif font stack
 * @public
 */
export const sanserifBase = '"Helvetica Neue",Helvetica,arial,tahoma,sans-serif';
export const serifBase = 'georgia,"times new roman",times,serif';

/**
 * A dictioanry of all font stacks
 * @public
 */
export const fontStacks: FontStacks = {
  htz: {
    primary: `var(--font-brand),${sanserifBase}`,
    secondary: sanserifBase,
    commercial: sanserifBase,
  },
  tm: {
    primary: `var(--font-brand),${sanserifBase}`,
    secondary: sanserifBase,
    commercial: sanserifBase,
  },
  hdc: {
    primary: `var(--font-brand),${serifBase}`,
    secondary: sanserifBase,
    commercial: sanserifBase,
  },
} as const;
