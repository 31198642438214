import { DEFAULT_BROWSER_FONT_SIZE } from '../lib/consts';
import { pxToRem } from '../lib/pxToRem';

import type { Site } from '@haaretz/s-types';

type RemValue = `${number}rem`;
type RadiusSeparator = ' ' | ' / ';
type BottomedOutRadiusValue =
  | `clamp(0px, ((100vw - 4px) - 100%) * 9999, ${RemValue})${RadiusSeparator}${RemValue}`
  | `clamp(0px, ((100vw - 4px) - 100%) * 9999, 50%)${RadiusSeparator}50%`
  | `clamp(0px, ((100vw - 4px) - 100%) * 9999, 9999px)${RadiusSeparator}9999px`;

/**
 * The value of a border radius token
 *
 * @remarks
 *   Uses rem units in order to scale with the base font size.
 *
 * @public
 */
export type RadiusTokenValue = 0 | BottomedOutRadiusValue;

/**
 * A site's named `border-radius` values
 *
 * @remarks
 *   Uses rem units in order to scale with the base font size.
 *
 * @public
 */
export interface SiteRadii {
  sharp: 0;
  small: RemValue;
  medium: RemValue;
  large: RemValue;
  xLarge: RemValue;
  xxLarge: RemValue;
  circle: '50%';
  pill: '9999px';
}

/**
 * A radius token name
 *
 * @public
 */
export type RadiusName = keyof SiteRadii;

/**
 * All sites' border radius tokens
 *
 * @public
 */
export type Radii = {
  readonly [key in Site]: Readonly<SiteRadii>;
};

/**
 * The default named `border-radius` values for a site
 *
 * @remarks
 *   Uses rem units in order to scale with the base font size.
 *
 * @public
 */
export const defaultSiteRadii = {
  sharp: 0,
  small: toRem(3),
  medium: toRem(4),
  large: toRem(8),
  xLarge: toRem(12),
  xxLarge: toRem(16),
  circle: '50%',
  pill: '9999px',
} as const;

/**
 * All sites' border radius tokens
 *
 * @public
 */
export const radii = {
  hdc: defaultSiteRadii,
  htz: defaultSiteRadii,
  tm: defaultSiteRadii,
} as const;

function toRem(pxValue: number): RemValue {
  return `${pxToRem(DEFAULT_BROWSER_FONT_SIZE, pxValue)}rem`;
}
