import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import LayoutContainer from '@haaretz/s-layout-container';
import s9 from 'style9';

type WrapperProps = {
  children: React.ReactNode;
};

const c = s9.create({
  base: {
    backgroundColor: color('footerBg'),
    color: color('footerColor'),
    paddingTop: space(8),
    paddingBottom: space(8),
    ...merge(
      mq({
        from: 'm',
        until: 'xl',
        value: {
          paddingTop: space(15),
          paddingBottom: space(15),
        },
      }),
      mq({
        from: 'xl',
        value: {
          paddingTop: space(22),
          paddingBottom: space(14),
          '--contentWidth': `minmax(0, ${space(306)})`,
        },
      }),
      mq({
        from: 'xxl',
        value: {
          '--contentWidth': `minmax(0, ${space(316)})`,
        },
      })
    ),
  },
  contentWrapper: {
    gridColumnStart: 'main',
    gridColumnEnd: 'main',
  },
});

export default function FooterWrapper({ children }: WrapperProps) {
  return (
    <LayoutContainer as="footer" styleExtend={[c.base]} data-testid="footer">
      <div className={s9(c.contentWrapper)}>{children}</div>
    </LayoutContainer>
  );
}
