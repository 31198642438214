'use client';
import fork from '@haaretz/l-fork.macro';
import IconSprite from '@haaretz/l-icon-sprite';
import { defaultTypeConfig } from '@haaretz/l-theme-shared';
import { globalErrorMetadata, site } from '@haaretz/s-consts';
import FooterWithoutData from '@haaretz/s-footer/WithoutData';
import openSans, { OPEN_SANS_VAR } from '@haaretz/s-next-font-open-sans';
import GlobalFonts from '@haaretz/s-next-layout/GlobalFonts';
import GlobalProviders from '@haaretz/s-next-layout/GlobalProviders';
import GlobalStyles from '@haaretz/s-next-layout/GlobalStyles';
import Icons from '@haaretz/s-next-layout/Icons';
import React from 'react';
import s9 from 'style9';

import ErrorPageTemplate from '../errorPageTemplate';

import type { AppGlobalType } from '@haaretz/s-data-structure-types';

const c = s9.create({
  base: {
    minHeight: '100dvh',
    display: 'grid',
    gridTemplateRows: '1fr auto',
  },
});

const initialStore: AppGlobalType = {
  animationReduced: false,
  articleId: null,
  articleType: null,
  authors: null,
  contrastMode: false,
  magazineCoverColor: null,
  page: null,
  pageStatusToken: null,
  pageType: 'Section',
  paywallType: null,
  platform: 'mobile',
  previewId: null,
  renderingKind: null,
  tags: null,
  teasersOnPage: null,
  user: null,
  zenMode: false,
  countryCode: 'IL',
};

export function ServerErrorBody() {
  return <ErrorPageTemplate variant={500} />;
}

// used for storybook
export function GlobalErrorContent() {
  return (
    <GlobalProviders initialGlobalStore={initialStore} useDefaultOnlyForAnonymous>
      <ServerErrorBody />
      <FooterWithoutData />
    </GlobalProviders>
  );
}

export default function ServerSideErrorPage() {
  const htmlSettings = fork({
    default: { dir: 'rtl', lang: 'he' },
    hdc: { dir: 'ltr', lang: 'en' },
  });

  return (
    // Wee need `suppressHydrationWarning` because our dark mode script
    // adds a class on the `html` element, which causes an hydration mismatch
    <html lang={htmlSettings.lang} dir={htmlSettings.dir} suppressHydrationWarning>
      {/* eslint-disable-next-line @next/next/no-head-element */}
      <head>
        <title>{globalErrorMetadata.title}</title>
        <Icons appName={site} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.__HTZ = window.__HTZ || {};
            `,
          }}
        />
        <GlobalStyles site={site} typeConfig={defaultTypeConfig} />
        <GlobalFonts fonts={[[openSans, OPEN_SANS_VAR]]} />
      </head>
      <body className={s9(c.base)}>
        <IconSprite />
        <GlobalErrorContent />
      </body>
    </html>
  );
}
