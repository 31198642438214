'use client';

import React from 'react';

const userHighContrastPreference =
  typeof window !== 'undefined' && window.matchMedia('(prefers-contrast: more)').matches;

const ContrastModeContext = React.createContext<boolean | undefined>(undefined);
const ContrastModeSetter = React.createContext<((contrastMode: boolean) => void) | undefined>(
  undefined
);

export function ContrastModeProvider({ children }: React.PropsWithChildren) {
  const [contrastMode, setContrastMode] = React.useState<boolean>(
    userHighContrastPreference || false
  );

  const contrastModeSetter = React.useCallback((value: boolean) => {
    setContrastMode(value);
  }, []);

  return (
    <ContrastModeContext.Provider value={contrastMode}>
      <ContrastModeSetter.Provider value={contrastModeSetter}>
        {children}
      </ContrastModeSetter.Provider>
    </ContrastModeContext.Provider>
  );
}

export default function useContrastMode() {
  const context = React.useContext(ContrastModeContext);

  if (context === undefined) {
    throw new Error('useContrastMode must be used within a ContrastModeProvider');
  }

  return context;
}

export function useSetContrastMode() {
  const context = React.useContext(ContrastModeSetter);

  if (context === undefined) {
    throw new Error('useSetContrastMode must be used within a ContrastModeProvider');
  }

  return context;
}

export function useContrastModeAtom() {
  return [useContrastMode(), useSetContrastMode()] as const;
}
