'use client';

import config from '@haaretz/l-config';
import useClientQuery from '@haaretz/s-client-data-hooks/useClientQuery';

type PurchaseVisitResponse = {
  date: string;
  url: string;
};

const SERVICE_URL = `${config.get('personalization')}/marketing/purchase-visit`;

export default function usePurchaseVisits() {
  return useClientQuery<PurchaseVisitResponse>({
    cacheKey: 'rainbowPurchaseVisit',
    url: SERVICE_URL,
    clientOptions: {
      cacheTime: 0,
      enabled: false,
    },
  });
}
