import useArticleType from '@haaretz/s-atoms/articleType';
import { usePathname } from 'next/navigation';

import type { ArticleType } from '@haaretz/s-types';

// Dont request for rainbow content on the following article-types
export const excludedArticleTypes: ArticleType[] = [
  'ConferenceArticle',
  'AdvertisementArticle',
  'AdvertisementMagazineArticle',
  'AdvertisementConferenceArticle',
];

const excludePages = ['/ty-search'];

export default function useIsRainbowEnabled() {
  const pathname = usePathname();

  const isPrint =
    typeof window !== 'undefined' && typeof window.matchMedia === 'function'
      ? window.matchMedia('print').matches
      : false;
  const articleType = useArticleType();

  return !(
    isPrint ||
    excludePages.includes(pathname) ||
    (articleType && excludedArticleTypes.includes(articleType))
  );
}
