import type { FontVariable } from '@haaretz/s-types';
import type { NextFontWithVariable } from 'next/dist/compiled/@next/font/dist/types';

type FontSettings = [NextFontWithVariable, FontVariable];

interface GlobalStylesProps {
  fonts: FontSettings[];
}

export default function GlobalFonts({ fonts }: GlobalStylesProps) {
  const fontVars = fonts && fonts.map(font => `${font[1]}:${font[0].style.fontFamily};`).join('\n');

  if (!fontVars) return null;

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
                  html {
                    ${fontVars}
                  }
                `,
      }}
    />
  );
}
